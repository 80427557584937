import React from 'react'
import Layout from '../components/Layout'
import img from '../img/404.jpg'

const NotFoundPage = () => (
  <Layout isProgramTypeSelected= {true}>
    <section className="section-page-not-found">
      <img src={img} alt="page not found" />
    </section>
  </Layout>
)

export default NotFoundPage
